import React, { useEffect } from "react";
import * as SentryJS from "@sentry/nextjs";
import axios from "axios";
import { useRouter } from "next/router";
import { IS_PRODUCTION } from "@/helpers/CompileHelper";
import "@/styles/application.scss";
import "@/helpers/i18n";
import * as gtag from "@/helpers/google/tag";
import * as gtm from "@/helpers/google/tag-manager";
import { AppProps } from "next/app";
import { responseInterceptor } from "@/components/axios/response-interceptor";
import Router from "@/components/routers";

global.Sentry = SentryJS;

declare global {
    // eslint-disable-next-line no-var
    var Sentry: typeof SentryJS;
}

function MyApp(props: AppProps) {
    const router = useRouter();

    useEffect(() => {
        if (!IS_PRODUCTION) return;

        const handleRouteChange = (url: string) => {
            gtag.pageview(url);
            gtm.pageview(url);
        }

        router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [router.events]);

    return <Router {...props}/>
}

export default MyApp;

axios.interceptors.response.use(responseInterceptor);
