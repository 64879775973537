const sentryIgnoreErrors = [
    "jQuery is not defined",
    "Can't find variable: jQuery",
    "undefined is not an object (evaluating 'window.ethereum.selectedAddress = undefined')",
    "_error.js getInitialProps missing data at path: /404",
    "There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root will switch to client rendering.",
    "Hydration failed because the initial UI does not match what was rendered on the server.",
    "Text content does not match server-rendered HTML.",
];

export default sentryIgnoreErrors;
