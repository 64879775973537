import { mergeWith, isArray } from "lodash";
import { TStateProps } from "./types";

export enum DispatchType {
    Load = "LOAD",
    Save = "SAVE",
    SaveStrict = "SAVE_STRICT",
    Update = "UPDATE",
    UpdateStrict = "UPDATE_STRICT",
    Overwrite = "OVERWRITE",
}

type ActionProps = {
    type: DispatchType,
    payload: object,
}

const Reducer = (
    state: TStateProps,
    { type, payload }: ActionProps
) => {
    const storageClass = state.storage === "local" ? localStorage : sessionStorage;

    const merge = (a: any, b: any) => {
        if (isArray(a) && isArray(b)) {
            return b;
        }
    }

    let _state = { ...state }
    switch (type) {
        case DispatchType.Load:
            return JSON.parse(storageClass.getItem(state.key) ?? "{}");

        case DispatchType.Save:
            // _state = merge(_state, payload);
            _state = mergeWith(_state, payload, (a, b) => merge(a, b))
            storageClass.setItem(state.key, JSON.stringify(_state));
            return _state;

        case DispatchType.SaveStrict:
            _state = { ..._state, ...payload }
            storageClass.setItem(state.key, JSON.stringify(_state));
            return _state;

        case DispatchType.Update:
            // return merge(_state, payload);
            return mergeWith(_state, payload, (a, b) => merge(a, b))

        case DispatchType.UpdateStrict:
            return { ..._state, ...payload };

        case DispatchType.Overwrite:
            storageClass.setItem(state.key, JSON.stringify(payload));
            return { ...payload };
    }
}

export default Reducer;
