import React, { createContext, useContext, useMemo, useReducer } from "react";
import GenericReducer, { ACTIONS } from "@/reducers/generic";
import { IS_SERVER } from "@/helpers/CompileHelper";

const GenericContext = createContext();

export const genericArgs = (props) => {
    if (IS_SERVER) return {};

    let saveTo = props?.saveTo ?? "local";
    let key = props?.key ?? "app";
    let storage = null;
    try {
        const storageClass = saveTo === "local" ? localStorage : sessionStorage;
        storage = JSON.parse(storageClass.getItem(key))
    } catch { /* */ }

    return {
        user: null,
        key,
        ...(storage ?? {}),
        saveTo,
    }
};

export const GenericWrapper = ({ children, reducer = GenericReducer, initArgs = (genericArgs()), actions = ACTIONS, defaultAction = ACTIONS.UPDATE }) => {
    const [state, dispatch] = useReducer(reducer, initArgs);

    const simpleDispatch = (payload) => {
        dispatch({
            payload,
            type: defaultAction,
        })
    }

    const dynamicStorage = () => {
        return (initArgs?.saveTo ?? "local") === "local" ? localStorage : sessionStorage;
    }

    const contextValue = useMemo(() => {
        return {
            state,
            dispatch,
            simpleDispatch,
            dynamicStorage,
            actions: actions,
            defaultAction: defaultAction,
        };
    }, [state, dispatch]);

    return (
        <GenericContext.Provider value={contextValue}>
            { children}
        </GenericContext.Provider>
    );
};

export function useGenericContext() {
    return useContext(GenericContext);
}
