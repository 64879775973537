import React from "react";
import DefaultHead from "@/components/default/head";
import { genericArgs, GenericWrapper } from "@/context/generic";
import { ACTIONS } from "@/reducers/generic";
import Link from "next/link";

const ContestedClaimsV2Wrapper = ({ Component, pageProps }) => {
    return (
        <div id="__contested-claims-v2">
            <DefaultHead/>
            <nav>
                <div className="row">
                    <Link href="https://mekle.no">
                        <div className="row--left"/>
                    </Link>
                </div>
            </nav>

            <GenericWrapper
                defaultAction={ACTIONS.SAVE}
                initArgs={genericArgs({ saveTo: "session" })}
            >
                <Component {...pageProps} />
            </GenericWrapper>

            <footer>
                <div className="row">
                    <div className="row--left"/>
                </div>
            </footer>
        </div>
    );
};

export default ContestedClaimsV2Wrapper;
