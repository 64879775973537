import React, { useEffect, useMemo, useState } from "react";
import { IS_SERVER } from "@/helpers/CompileHelper";

const Hamburger = (props) => {
    const [currentY, setY] = useState(0);

    useEffect(() => {
        if (IS_SERVER) return;

        if (props.active) {
            setY(window.scrollY);
            document.body.classList.add("stop-scrolling");
        } else {
            document.body.classList.remove("stop-scrolling");
            window.scroll(0, currentY)
        }
    }, [props.active])

    const isActive = useMemo(() => {
        return props.active ? " show" : "";
    }, [props.active])

    return (
        <div className={ "hamburger__container" + isActive }>
            <div className="hamburger__body">
                <a href="https://mekle.no/slik-fungerer-det/">
                    <span>
                        Slik fungerer det
                    </span>
                </a>
                <a href="https://mekle.no/vare-meklere/">
                    <span>
                        Våre meklere
                    </span>
                </a>
                <a href="https://mekle.no/hvorfor-mekle/">
                    <span>
                        Hvorfor Mekle?
                    </span>
                </a>
                <a href="https://mekle.no/ofte-stilte-sporsmal/">
                    <span>
                        FAQ
                    </span>
                </a>
                <a href="https://meldsak.mekle.no/">
                    <div className="button__link background--black">
                        Meld sak
                    </div>
                </a>
                <a href="https://minside.mekle.no/">
                    <div className="button__link background--black">
                        Min side
                    </div>
                </a>
                {/*<div className="button__locale">*/}
                {/*    <span>EN</span> / <span className="active">NO</span>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default Hamburger;
