import React from "react";
import ContestedClaimsV2Wrapper from "@/components/route-wrappers/contested-claims-v2";
import { useRouter } from "next/router";
import DefaultRouter from "@/components/routers/default";
import { ServiceWrapper } from "@/context/ServiceContext";
import RouterV2 from "@/components/routers/v2";

const Router = (props) => {
    const router = useRouter();
    const notFound =
        router.asPath.includes("/404") || router.pathname.includes("/404") ||
        (router.pathname.length === 1 && router.asPath.length > 1);

    // if (/^\/demo/.test(router?.asPath ?? "") && !notFound) {
    //     return <ContestedClaimsV2Wrapper {...props}/>;
    // }
    //
    // if (/^\/bestridte-krav/.test(router?.asPath ?? "") && !notFound) {
    //     return <ContestedClaimsV1Wrapper {...props}/>;
    // }

    if (/^\/bestridte-krav/.test(router?.asPath ?? "") && !notFound) {
        return <ContestedClaimsV2Wrapper {...props}/>;
    }

    if (/^\/finn/.test(router?.asPath ?? "") && !notFound) {
        return <RouterV2 {...props} storageKey="finn"/>;
    }

    if (/^\/samlivsbrudd/.test(router?.asPath ?? "") && !notFound) {
        return <RouterV2 {...props} storageKey="divorce"/>;
    }

    return (
        <ServiceWrapper>
            <DefaultRouter {...props}/>
        </ServiceWrapper>
    );
};

export default Router;
