import React, { createContext, useContext, useMemo, useReducer } from "react";
import formReducer, { ACTIONS } from "@/components/FormReducer";
import { initialiseFormPayload } from "@/helpers/FormHelper";

const ServiceContext = createContext();

export const ServiceWrapper = (props) => {
    const [state, dispatch] = useReducer(formReducer, initialiseFormPayload());

    const contextValue = useMemo(() => {
        if (props?.contextRef != null) {
            props.contextRef.current = { state, dispatch };
        }

        return {
            state: state,
            dispatch: dispatch,
            actions: ACTIONS,
            defaultAction: ACTIONS.SAVE,
        };
    }, [state, dispatch]);

    return (
        <ServiceContext.Provider value={contextValue}>
            {props?.children}
        </ServiceContext.Provider>
    );
}

export function useServiceContext() {
    return useContext(ServiceContext);
}
