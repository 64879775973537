// Implementation based on Vercel's example repo:
// https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics

import Script from "next/script";
import React from "react";
import { IS_DEVELOPMENT } from "@/helpers/CompileHelper";

export const GA_TRACKING_ID = "G-10L00M2BZ0";

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
    window.gtag("config", GA_TRACKING_ID, {
        page_path: url,
    })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
    window.gtag("event", action, {
        event_category: category,
        event_label: label,
        value: value,
    })
}

export const reportConversion = (send_to, url) => {
    try {
        var callback = function () {
            if (typeof (url) != 'undefined') {
                window.location = url;
            }
        };

        window.gtag('event', 'conversion', {
            'send_to': `AW-10875649054/${send_to}`,
            'event_callback': callback
        });
    } catch (e) {
        if (IS_DEVELOPMENT) {
            console.error(e);
        }
    }

    return false;
}

export const render = () => {
    return (
        <>
            {/* Global Site Tag (gtag.js) - Google Analytics */}
            <Script
                strategy="afterInteractive"
                src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
            />
            <Script
                id="gtag-init"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', '${GA_TRACKING_ID}', {
                            'page_path': window.location.pathname,
                            'linker': {
                                'domains': ['mekle.no', 'meldsak.mekle.no', 'kontakt.mekle.no', 'info.mekle.no'],
                                'accept_incoming': true
                            }
                        });
                    `,
                }}
            />
        </>
    );
}
