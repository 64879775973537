import React from "react";
import Link from "next/link";

const DefaultNav = (props) => {
    if (props?.hidden) {
        return null;
    }

    return (
        <nav>
            <div className="nav__row">
                <Link href="https://mekle.no">
                    <div className="nav__row--left"/>
                </Link>
                <div className="nav__row--right">
                    <div
                        className={ "hamburger background--lemon" + (props?.burgerState ? " open" : "") }
                        onClick={() => props.setBurgerState(!props.burgerState)}
                    />
                    {/*<a href="#">*/}
                    {/*    <span>*/}
                    {/*        Slik fungerer det*/}
                    {/*    </span>*/}
                    {/*</a>*/}
                    {/*<a href="#">*/}
                    {/*    <span>*/}
                    {/*        Våre meklere*/}
                    {/*    </span>*/}
                    {/*</a>*/}
                    {/*<a href="#">*/}
                    {/*    <span>*/}
                    {/*        Hvorfor Mekle?*/}
                    {/*    </span>*/}
                    {/*</a>*/}
                    {/*<a href="#">*/}
                    {/*    <span>*/}
                    {/*        FAQ*/}
                    {/*    </span>*/}
                    {/*</a>*/}
                    {/*<a href="#">*/}
                    {/*    <div className="button__link background--lemon">*/}
                    {/*        Meld sak*/}
                    {/*    </div>*/}
                    {/*</a>*/}
                    {/*<a href="#">*/}
                    {/*    <div className="button__link background--lemon">*/}
                    {/*        Min side*/}
                    {/*    </div>*/}
                    {/*</a>*/}
                    {/*<div className="button__locale">*/}
                    {/*    <span>EN</span> / <span className="active">NO</span>*/}
                    {/*</div>*/}
                </div>
            </div>
        </nav>
    );
};

export default DefaultNav;
