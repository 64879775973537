var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"bNZE1wIRPi15ZvVqVo07t"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import sentryIgnoreErrors from "@/configs/sentry-ignore-errors";

const DEV_ENVIRONMENT = process?.env?.NODE_ENV === "development";

const sentry_dns = () => {
    if (DEV_ENVIRONMENT) return "https://6a32b9c76e49439994408e8abbf85baf@o322560.ingest.sentry.io/6555163";
    return "https://3ee259589fcd429ba15f3c315b5c8131@o323053.ingest.sentry.io/5441381";
}

const tunnel_url = () => {
    if (DEV_ENVIRONMENT) return "http://127.0.0.1:3089/api/v2/sentry/tunnel";
    return "https://mekle-prod-01.herokuapp.com/api/v2/sentry/tunnel";
}

Sentry.init({
    dsn: sentry_dns(),
    tunnel: tunnel_url(),
    tracesSampleRate: 1.0, // Adjust this value in production, or use tracesSampler for greater control
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    ignoreErrors: sentryIgnoreErrors,
});
