export function responseInterceptor(response) {
    if (process?.env?.NODE_ENV === "development" && typeof window !== "undefined") {
        console.log("Development Log", response?.data)
    }

    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    const version_index = response?.config?.url?.indexOf("/api/v");
    if (version_index >= 0) {
        const api_version = parseInt(response?.config?.url?.substr(version_index, 8)?.match(/[0-9]+/g)?.join("") ?? 0);
        if (api_version >= 2) {
            if (typeof Blob !== "undefined" && response?.data instanceof Blob) {
                return response;
            }

            return response?.data;
        }
    }

    return response;
}
