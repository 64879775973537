import React, { createContext, useReducer } from "react";
import {
    IContext,
    TArgumentProps,
    TStateProps,
    TWrapperProps,
} from "./types";
import Reducer, { DispatchType } from "./reducer";

const IS_SERVER = typeof window === "undefined";

const initialArgs = ({ load, ...args }: TArgumentProps): TStateProps => {
    const key = args?.storageKey ?? "state";
    const storage = args?.storage ?? "session";

    let data = {}
    if (!IS_SERVER && load) {
        const storageClass = storage === "local" ? localStorage : sessionStorage;
        data = JSON.parse(storageClass.getItem(key) ?? "{}");
    }

    return {
        key: key,
        storage: storage,
        conditions: {
            disabled: false,
            locked: false,
            submitted: false,
        },
        ...data,
    }
}

const StateContext = createContext<IContext>({
    state: initialArgs({}),
    reset: () => {},
    save: (_payload) => {},
    update: (_payload) => {},
    dispatch: (_payload) => {},
});

export const StateWrapper = ({ children, behaviour = "save", ...args }: TWrapperProps) => {
    const [state, dispatch] = useReducer(Reducer, initialArgs({ load: true, ...args }));
    const keys = ["key", "storage", "conditions"];

    const _dispatch = (payload: DeepPartial<TStateProps>, strict = false) => {
        switch (behaviour) {
            case "save":
                save(payload, strict);
                return;

            case "update":
                update(payload, strict);
                return;
        }
    }

    const data = {
        get payload() {
            return Object.keys(state).reduce((acc: Record<string, any>, key) => {
                if (!keys.includes(key)) {
                    acc[key] = state[key];
                }
                return acc;
            }, {});
        },
    }

    const reset = () => {
        dispatch({
            type: DispatchType.Overwrite,
            payload: initialArgs(args),
        })
    }

    const save = (payload: DeepPartial<TStateProps>, strict = false) => {
        dispatch({
            type: strict ? DispatchType.SaveStrict : DispatchType.Save,
            payload,
        })
    }

    const update = (payload: DeepPartial<TStateProps>, strict = false) => {
        dispatch({
            type: strict ? DispatchType.UpdateStrict : DispatchType.Update,
            payload,
        })
    }

    // TODO - does this have to be encapsulated by a useMemo?
    const value = {
        state: state as TStateProps,
        dispatch: _dispatch,
        save,
        update,
        reset,
        data: data.payload,
    }

    return (
        <StateContext.Provider value={value}>
            { children }
        </StateContext.Provider>
    );
};

export function useStateContext() {
    return React.useContext(StateContext)
}
