export const findStage = (mode, path, state, safe = true) => {
    if (mode == null) return;
    let stage = Stages[mode];
    let url = path;
    if (!safe) {
        url = url.split("?")[0];
        if (url[url.length - 1] === "/") {
            url = url.substr(0, url.length - 1);
        }
        url = url.replace(mode, "[mode]");
    }

    for (let i = 0; i < stage.length; i++) {
        if (stage[i]?.url === url) {
            let index = i;
            let next = stage[i + 1]?.url ?? null;
            let back = stage[i - 1]?.url ?? null;

            for (let j = i + 1; j < stage.length; j++) {
                if (stage[j]?.skip == null || !stage[j]?.skip?.(state)) {
                    next = stage[j]?.url ?? null;
                    index = j - 1;
                    break;
                }
            }

            return {
                index,
                back: viableUrl(mode, back),
                next: viableUrl(mode, next),
            };
        }
    }

    return null;
}

const viableUrl = (mode, url) => {
    return url?.replace?.("[mode]", mode) ?? null;
}

const skipOnInsurance = state => {
    if (Number(state?.is_assisted?.index ?? -1) === 0) {
        if (Number(state?.assisting?.index ?? -1) === 1) {
            return true;
        }
    }

    return false;
}

export const Stages = {
    privat: [
        { url: "/[mode]" },
        { url: "/[mode]/kontekst" },
        { url: "/[mode]/saksbeskrivelse" },
        { url: "/[mode]/kostnadene", skip: skipOnInsurance },
        { url: "/[mode]/send-skjema", upload: true },
    ],

    virksomhet: [
        { url: "/[mode]" },
        { url: "/[mode]/kontekst" },
        { url: "/[mode]/saksbeskrivelse" },
        { url: "/[mode]/kostnadene", skip: skipOnInsurance },
        { url: "/[mode]/send-skjema", upload: true },
    ],
};
