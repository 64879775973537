import React from "react";
import { ACTIONS } from "@/components/FormReducer";
import { useRouter } from "next/router";
import { useServiceContext } from "@/context/ServiceContext";

const DefaultFooter = (props) => {
    const context = useServiceContext();
    const { dispatch } = context;

    const router = useRouter();

    const devReset = () => {
        if (props?.setShow == null) return;

        dispatch({ type: ACTIONS.RESET });
        router.push("/");
        props.setShow(false);
    };

    if (props?.hidden) {
        return null;
    }

    return (
        <>
            <div className={ "funnel__restart" + (props.show ? " show" : "") }>
                <div className={ "funnel__container center" + (props.show ? " show" : "") }>
                    <p className="mb-5">
                        Ønsker du å starte helt på nytt?
                    </p>

                    <div className="input__group">
                        <div className="button background--white" onClick={devReset}>
                            Ja
                        </div>
                        <div className="button background--white" onClick={() => props?.setShow(!props.show)}>
                            Nei
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer">
                <div className="footer__row">
                    <div className="footer__row--left" onClick={() => props?.setShow(!props.show)}/>
                </div>
            </div>
        </>
    );
};

export default DefaultFooter;
