import React, { useEffect, useState } from "react";
import DefaultHead from "@/components/default/head";
import useUpdateEffect from "@/hooks/UpdateEffect";
import { useServiceContext } from "@/context/ServiceContext";
import { IS_SERVER } from "@/helpers/CompileHelper";
import { useRouter } from "next/router";
import { findStage } from "@/data/Stages";
import { initialiseClient } from "@/helpers/FormHelper";
import DefaultNav from "@/components/default/nav";
import DefaultFooter from "@/components/default/footer";
import Hamburger from "@/components/Hamburger";

const DefaultRouter = ({ Component, pageProps }) => {
    const context = useServiceContext();
    const { state, dispatch, actions } = context;

    const router = useRouter();

    const [burgerState, setBurgerState] = useState(false);
    const [show, setShow] = useState(false);
    const [paths, setPaths] = useState([]);

    useEffect(() => {
        setPaths(router.pathname.split("/").filter(part => part !== ""));
    }, [router]);

    useEffect(() => {
        const onRouteChangeUrlPrefetching = (url) => {
            prefetchStageUrls(findStage(pageProps?.mode, url, state, false));
        }

        router.events.on("routeChangeComplete", onRouteChangeUrlPrefetching);
        return () => {
            router.events.off("routeChangeComplete", onRouteChangeUrlPrefetching);
        };
    }, [router.events])

    useUpdateEffect(() => {
        if (IS_SERVER) return;

        if ((state?.shared?.reference?.length ?? 0) === 0) {
            initClient();
        }

        if (state?.shared?.stage != null) {
            prefetchStageUrls(findStage(
                pageProps?.mode,
                router.route,
                state
            ))
        }
    }, [state?.shared])

    const initClient = async () => {
        dispatch({
            type: actions.SAVE,
            payload: {
                shared: {
                    reference: await initialiseClient(pageProps),
                },
            },
        })
    }

    const prefetchStageUrls = (stage) => {
        if (stage?.back?.length > 0) {
            router.prefetch(stage?.back);
        }

        if (stage?.next?.length > 0) {
            router.prefetch(stage?.next);
        }
    }

    return (
        <div id="__mekle">
            <DefaultHead/>
            <DefaultNav
                hidden={paths[0] === "help"}
                burgerState={burgerState}
                setBurgerState={setBurgerState}
            />
            <Hamburger active={burgerState}/>
            <Component {...pageProps}/>
            <DefaultFooter
                hidden={paths[0] === "help"}
                show={show}
                setShow={setShow}
            />
        </div>
    );
};

export default DefaultRouter;
