import React from "react";
import { AppProps } from "next/app";
import { StateWrapper } from "@/components/state";
import DefaultHead from "@/components/default/head";
import Link from "next/link";

const RouterV2 = ({ Component, pageProps, ...rest }: AppProps) => {
    const { storageKey }: any = rest;

    return (
        <div id="__v2">
            <DefaultHead/>
            <nav>
                <div className="row">
                    <Link href="https://mekle.no">
                        <div className="row--left"/>
                    </Link>

                    <div className="row--right nav__buttons">
                        {/*<Link href="https://mekle.no/slik-fungerer-det/">*/}
                        {/*    <div>Sakstyper</div>*/}
                        {/*</Link>*/}
                        <Link href="https://mekle.no/slik-fungerer-det/" passHref>
                            <a>Slik fungerer det</a>
                        </Link>
                        <Link href="https://mekle.no/vare-meklere/">
                            <a>Våre meklere</a>
                        </Link>
                        <Link href="https://mekle.no/hvorfor-mekle/">
                            <a>Hvorfor Mekle?</a>
                        </Link>
                        <Link href="https://mekle.no/pris/">
                            <a>Pris</a>
                        </Link>
                        <Link href="https://meldsak.mekle.no/">
                            <a className="nav__button--cta">Meld sak</a>
                        </Link>
                        <Link href="https://minsak.mekle.no/">
                            <a className="nav__button--cta">Min sak</a>
                        </Link>
                        <div className="nav__button--language">NO</div>
                    </div>
                </div>
            </nav>

            <StateWrapper storageKey={storageKey}>
                <Component {...pageProps} />
            </StateWrapper>
        </div>
    );
};

export default RouterV2;
