import { merge } from "lodash";
import { KEYS } from "@/helpers/FormHelper";

export const ACTIONS = {
    RESET_DIRTY: "reset-dirty",
    STRICT_SAVE: "strict-save",
    SAVE: "save",
    LOAD: "load",
    RESET: "reset",
    UPDATE: "update",
    DELETE: "delete",
    UPLOADING: "uploading",
}

const FormReducer = (state, action) => {
    let stateKey = KEYS?.payload ?? "form-state";
    let updatedState = null;

    switch (action.type) {
        case ACTIONS.DELETE:
            sessionStorage.removeItem(stateKey);
            return { shared: state.shared };

        case ACTIONS.RESET:
            for (let key in KEYS) {
                sessionStorage.removeItem(KEYS[key]);
            }
            return null;

        case ACTIONS.LOAD:
            let payload = JSON.parse(sessionStorage.getItem(stateKey));
            return { ...payload };

        case ACTIONS.UPDATE:
            updatedState = { ...state };
            updatedState = merge(updatedState, action.payload);
            updatedState = merge(updatedState, { shared: { is_dirty: true }});
            return updatedState;

        case ACTIONS.SAVE:
            updatedState = { ...state };
            updatedState = merge(updatedState, action.payload);
            updatedState = merge(updatedState, { shared: { is_dirty: true }});
            sessionStorage.setItem(stateKey, JSON.stringify(updatedState));
            return updatedState;

        // Strict save is "dangerous" to use when dealing with arrays. Look at using normal save instead paired with Lodash 'unset'.
        case ACTIONS.STRICT_SAVE:
            updatedState = { ...state, ...action.payload };
            updatedState = merge(updatedState, { shared: { is_dirty: false }});
            sessionStorage.setItem(stateKey, JSON.stringify(updatedState));
            return updatedState;

        case ACTIONS.RESET_DIRTY:
            updatedState = { ...state };
            updatedState = merge(updatedState, { shared: { is_dirty: false }});
            return updatedState;

        case ACTIONS.UPLOADING:
            updatedState = { ...state };
            updatedState = merge(updatedState, { shared: { uploading: action.payload ?? true }});
            return updatedState;

        default:
            throw new Error("Unknown reducer action: " + action.type);
    }
};

export default FormReducer
