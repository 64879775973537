import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationNO from "@/locales/no/common.json";

// the translations
const resources = {
    no: {
        translation: translationNO,
    },
};

export const detectorOptions = {
    order: ["cookie", "localStorage", "navigator"],

    lookupCookie: "i18next",
    lookupLocalStorage: "i18nextLng",
    lookupFromPathIndex: 0,
};

i18n
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        detection: detectorOptions,
        fallbackLng: {
            "us": ["en"],
            "uk": ["en"],
            "nb": ["no"],
            "nn": ["no"],
            "default": ["no"],
        },

        interpolation: {
            escapeValue: false, // react already safes from xss
        },

        react: {
            bindI18n: "languageChanged",
        },

        returnEmptyString: false,
    });

export const currentLanguage = () => {
    return localStorage.getItem(detectorOptions.lookupLocalStorage);
}

export const changeLanguage = (language, force = false) => {
    let cl = currentLanguage();
    if ((cl !== "no" && cl !== language) ||
        (force && cl !== language)) {
        i18n.changeLanguage(language);
    }
};

export default i18n;
