import { IS_SERVER } from "@/helpers/CompileHelper";
import useApi from "@/synega/hooks/useApi";

export const KEYS = {
    payload: "client_payload",
    reference: "client_reference",
    temp: {
        is_assisted: "temp_is_assisted",
        reference: "temp_reference",
    },
};

export const initialiseFormPayload = (props) => {
    if (IS_SERVER) return;

    let client_reference = sessionStorage.getItem(KEYS.reference);
    let payload = JSON.parse(sessionStorage.getItem(KEYS.payload));

    return {
        ...payload,
        shared: {
            version: "1",
            reference: client_reference,
        },
    }
};

// In development, because of React Strict Mode, this will likely run twice.
export const initialiseClient = async (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const api = useApi();
    let reference = "";

    await api.post("/clients", {
        category: props?.details?.id,
    }).then(res => {
        if (res?.data?.reference?.length > 0) {
            reference = res?.data?.reference;
            sessionStorage.setItem(KEYS.reference, res?.data?.reference);
        }
    }).catch(error => {
        console.log(error?.response?.data?.messages);
    });
    return reference;
};
