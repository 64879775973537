import React from "react";
import * as gtag from "@/helpers/google/tag";
import * as gtm from "@/helpers/google/tag-manager";
import Head from "next/head";

const DefaultHead = (props) => {
    const image = props?.image ?? "/static/images/mekle.png";
    const title = props?.title ?? "Meld sak | Mekle";

    const isProduction = process.env.NODE_ENV === "production";

    return (
        <>
            { isProduction && gtag.render() }
            { isProduction && gtm.render() }

            <Head>
                <title>{title}</title>
                <meta property="og:title" content={ title }/>
                <meta name="twitter:title" content={ title }/>

                <meta property="og:site_name" content={ title }/>
                <meta property="og:type" content="website"/>

                <meta name="twitter:image" content={ image }/>
                <meta property="og:image" content={ image }/>

                <meta property="og:url" content="https://meldsak.mekle.no"/>
                <meta name="author" content="Mekling AS"/>
            </Head>
        </>
    );
};

export default DefaultHead;
